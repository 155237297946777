<template>
  <div
    id="contents"
    :class="tabIndex == 1 ? 'product_release_register' : 'product_release'"
  >
    <div class="contents_head">
      <h2>{{ $getPageTitle($route.path) }}</h2>
      <div class="aside" v-if="$screen.width < 1280">
        <aside-selectric
          :id="'tabletAsideSelectric'"
          :watch="tabIndex"
          :commit="'setOpenTabIndexToOutputPage'"
          :options="[
            { title: '수주 기준 매출', name: 'sales_base_output' },
            { title: '제품 기준 매출', name: 'product_base_output' },
            { title: '매출 조회 및 관리', name: 'output_management' },
          ]"
        >
        </aside-selectric>
      </div>
      <div class="management_btn_group">
        <div>
          <span
            class="add_favorite"
            @click="AddFavorite"
            :class="{ on: isFavorOn }"
            >즐겨찾기 추가</span
          >
          <!-- <a
            @click="ToggleManagementMode()"
            :class="managementMode ? 'btn_admin on' : 'btn_admin'"
            href="#">관리</a
          > -->
          <!-- <button class="btn_admin" :disabled="!managementMode">저장</button> -->
          <button class="btn_admin" @click="CloseThisPage()">닫기</button>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="aside" v-if="$screen.width >= 1280">
        <ul>
          <li :class="{ active: tabIndex == 0 }" v-if="isPermissionOn">
            <a @click="SetOpenTabIndex(0)">수주 기준 매출</a>
          </li>
          <li :class="{ active: tabIndex == 1 }" v-if="isPermissionOn">
            <a @click="SetOpenTabIndex(1)">제품 기준 매출</a>
          </li>
          <li
            :class="{ active: isPermissionOn ? tabIndex == 2 : tabIndex == 0 }"
          >
            <a @click="SetOpenTabIndex(2)">매출 조회 및 관리</a>
          </li>
        </ul>
      </div>
      <sales-base-form
        v-if="isPermissionOn ? tabIndex == 0 : tabIndex == -1"
      ></sales-base-form>
      <product-base-form v-if="tabIndex == 1"></product-base-form>
      <output-management-form
        v-if="isPermissionOn ? tabIndex == 2 : tabIndex == 0"
      ></output-management-form>
      <!-- <sales-new-form v-if="tabIndex == 1"></sales-new-form> -->
    </div>
  </div>
</template>

<script>
import SalesBaseForm from '@/views/forms/Product/Output/SalesBaseForm';
import ProductBaseForm from '@/views/forms/Product/Output/ProductBaseForm';
import OutputManagementForm from '@/views/forms/Product/Output/OutputManagementForm';
import ClosePageMixin from '@/mixins/closePage';
import FavoriteMixin from '@/mixins/favorite';
import SpinnerModal from '@/mixins/spinner';
import ModalMixin from '@/mixins/modal';
import AsideSelectric from '@/layouts/components/AsideSelectric';
import { mapGetters, mapMutations } from 'vuex';

export default {
  mixins: [ClosePageMixin, SpinnerModal, ModalMixin, FavoriteMixin],
  components: {
    SalesBaseForm,
    ProductBaseForm,
    OutputManagementForm,
    AsideSelectric,
  },
  computed: {
    ...mapGetters({
      managementMode: 'getManagementModeFromOutputPage',
      tabIndex: 'getOpenTabIndexFromOutputPage',
    }),
    // filteredTabIndex() {
    //   if (this.isPermissionOn) {
    //     console.log('PERMISSION ON', this.isPermissionOn);
    //     if (this.tabIndex == 2) {
    //       return this.tabIndex == 0;
    //     } else {
    //       return this.tabIndex == -1;
    //     }
    //   } else {
    //     console.log('PERMISSION OFF', this.isPermissionOn);
    //     if (this.tabIndex == 0) {
    //       return this.tabIndex == 0;
    //     } else if (this.tabIndex == 1) {
    //       return this.tabIndex == 1;
    //     } else {
    //       return this.tabIndex == 2;
    //     }
    //   }
    // },
  },
  methods: {
    ...mapMutations({
      ToggleManagementMode: 'toggleManagementModeToOutputPage',
      SetOpenTabIndex: 'setOpenTabIndexToOutputPage',
    }),
  },
  async created() {
    if (this.$route.meta.menu_init == true) {
      this.$store.commit('InitOutputPage');
      this.$route.meta.menu_init = false;
    }
    // if (this.isPermissionOn) {
    //   console.log('this.isPermissionOn', this.isPermissionOn);
    //   this.SetOpenTabIndex(2);
    // }
  },
};
</script>

<style></style>
